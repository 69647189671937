html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #003e49;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
}

.home-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content-wrapper {
  text-align: center;
}

.content-wrapper h2 {
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 1.25rem;
}
